import { ref } from 'vue'

const chatVisibility = ref('minimized')

const openChat = () => {
	chatVisibility.value = 'maximized'
}

const updateChatVisibility = (data) => {
	chatVisibility.value = data.visibility
}
export { chatVisibility, openChat, updateChatVisibility }
